import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import { setAuthToken } from "./src/api/http"
import useStore from "./src/redux/store"

export default ({ element }) => {
  const { store, persistor } = useStore()

  function setAuthTokenAfterPersist() {
    const { token } = store.getState().session
    setAuthToken(token)
  }

  return (
    <Provider store={store}>
      <PersistGate
        loading={<div>Loading...</div>}
        persistor={persistor}
        onBeforeLift={setAuthTokenAfterPersist}
      >
        {() => <>{element}</>}
      </PersistGate>
    </Provider>
  )
}
