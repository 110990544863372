import { combineReducers } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import eventReducer from "./slices/event"
import storage from "redux-persist/lib/storage"

import sessionReducer from "./slices/session"
import jobfairReducer from "./slices/jobfair"
import jobsReducer from "./slices/jobs"
import playgroundReducer from "./slices/playground"

const sessionPersistConfig = {
  key: "session",
  storage,
  whitelist: ["account", "token"],
}

const jobFairPersistConfig = {
  key: "jobfair",
  storage,
  whitelist: ["searchHistory", "customKeyword"],
}

export default combineReducers({
  session: persistReducer(sessionPersistConfig, sessionReducer),
  jobfair: persistReducer(jobFairPersistConfig, jobfairReducer),
  playground: playgroundReducer,
  jobs: jobsReducer,
  event: eventReducer,
})
