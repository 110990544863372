import axios from "axios"
import constants from "../constant"

const baseUrl = `${constants.API_BASE_URL}/registrations`

export default {
  getMe: () => axios.get(`${baseUrl}/me/`),

  getMyTeams: () => axios.get(`${baseUrl}/me/teams/`),

  getEvents: () => axios.get(`${baseUrl}/events/`),

  getEvent: (eventId) => axios.get(`${baseUrl}/events/${eventId}/`),

  getTeam: (eventId, teamId) =>
    axios.get(`${baseUrl}/events/${eventId}/teams/${teamId}/`),

  postTeams: (eventId, name) => {
    return axios.post(`${baseUrl}/events/${eventId}/teams/`, { name })
  },

  patchTeam: (eventId, teamId, name) => {
    const formData = new FormData()
    formData.append("name", name)
    return axios.patch(
      `${baseUrl}/events/${eventId}/teams/${teamId}/`,
      formData
    )
  },

  deleteTeamMember: (eventId, teamId, memberId) =>
    axios.post(
      `${baseUrl}/events/${eventId}/teams/${teamId}/remove_member/${memberId}/`
    ),

  deleteTeam: (eventId, teamId) =>
    axios.delete(`${baseUrl}/events/${eventId}/teams/${teamId}/`),

  postJoinTeam: (token) =>
    axios.post(`${baseUrl}/join-team/`, { team_token: token }),

  deleteJoinTeam: () => axios.delete(`${baseUrl}/join-team/`),

  postMembershipRequest: (eventId, teamId, membershipRequestId) =>
    axios.post(
      `${baseUrl}/events/${eventId}/teams/${teamId}/membership-requests/${membershipRequestId}/`
    ),

  deleteMembershipRequest: (eventId, teamId, membershipRequestId) =>
    axios.delete(
      `${baseUrl}/events/${eventId}/teams/${teamId}/membership-requests/${membershipRequestId}/`
    ),

  getTasks: (eventId, teamId) =>
    axios.get(`${baseUrl}/events/${eventId}/teams/${teamId}/tasks/`),

  patchTasks: (eventId, teamId, taskId, fields, attachments, options) => {
    const formData = new FormData()
    Object.keys(fields).forEach((key) => {
      if (typeof fields[key] === "string") {
        fields[key].length && formData.append(key, fields[key])
      } else {
        formData.append(key, JSON.stringify(fields[key]))
      }
    })
    Object.keys(attachments).forEach((key) => {
      formData.append(key, attachments[key])
    })
    return axios.patch(
      `${baseUrl}/events/${eventId}/teams/${teamId}/tasks/${taskId}/`,
      formData,
      options
    )
  },
}
