const SET_EVENTS = "event/SET_EVENTS"
const CLEAR_EVENTS = "event/CLEAR_EVENTS"
const SET_TEAMS = "event/SET_TEAMS"
const CLEAR_TEAMS = "event/CLEAR_TEAMS"
const SET_TEAM = "event/SET_TEAM"
const UPDATE_TEAM = "event/UPDATE_TEAM"
const DELETE_TEAM = "event/DELETE_TEAM"
const CLEAR_TEAM = "event/CLEAR_TEAM"
const CLEAR_ALL = "event/CLEAR_ALL"
const SET_MEMBERSHIP_REQUESTS = "event/SET_MEMBERSHIP_REQUESTS"
const CLEAR_MEMBERSHIP_REQUESTS = "event/CLEAR_MEMBERSHIP_REQUESTS"
const SET_TAGS = "event/SET_TAGS"
const SET_TASK = "event/SET_TASK"
const SET_ANNOUNCEMENTS = "event/SET_ANNOUNCEMENTS"
const LOADING_TEAMS = "event/LOADING_TEAMS"
const LOADED_TEAMS = "event/LOADED_TEAMS"

const INITIAL_STATE = {
  events: undefined,
  teams: undefined,
  membershipRequests: undefined,
  team: undefined,
  loading: false,
}

export default function eventReducer(state, action) {
  switch (action.type) {
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      }
    case SET_TASK:
      return {
        ...state,
        teams: {
          ...state.teams,
          [action.payload.teamId]: {
            ...state.teams[action.payload.teamId],
            tasks: {
              ...state.teams[action.payload.teamId][action.payload.taskId],
              [action.payload.taskId]: {
                ...action.payload.task,
              },
            },
          },
        },
      }
    case SET_MEMBERSHIP_REQUESTS:
      return {
        ...state,
        membershipRequests: [...action.payload],
      }
    case CLEAR_MEMBERSHIP_REQUESTS:
      return {
        ...state,
        membershipRequests: null,
      }
    case SET_EVENTS:
      return {
        ...state,
        events: {
          ...state.events,
          ...action.payload,
        },
      }
    case CLEAR_EVENTS:
      return {
        ...state,
        events: null,
      }
    case SET_TEAMS:
      return {
        ...state,
        teams: action.payload,
      }
    case CLEAR_TEAMS:
      return {
        ...state,
        teams: null,
      }
    case SET_TEAM:
      return {
        ...state,
        team: { ...action.payload },
      }
    case UPDATE_TEAM:
      return {
        ...state,
        teams: {
          ...state.teams,
          [action.payload.id]: {
            ...state.teams[action.payload.id],
            ...action.payload,
          },
        },
      }
    case DELETE_TEAM:
      return {
        ...state,
        teams: {
          ...state.teams,
          [action.payload]: undefined,
        },
      }
    case CLEAR_TEAM:
      return {
        ...state,
        team: null,
      }
    case CLEAR_ALL:
      return {
        events: null,
        teams: null,
        team: null,
      }
    case SET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload,
      }
    case LOADING_TEAMS:
      return {
        ...state,
        loading: true,
      }
    case LOADED_TEAMS:
      return {
        ...state,
        loading: false,
      }
    default:
      return state === undefined ? INITIAL_STATE : state
  }
}

export const eventActions = {
  setEvents: (events) => ({
    type: SET_EVENTS,
    payload: events,
  }),

  setTags(tags) {
    return { type: SET_TAGS, payload: tags }
  },

  setTask(taskId, teamId, task) {
    return {
      type: SET_TASK,
      payload: {
        taskId,
        teamId,
        task,
      },
    }
  },

  setAnnouncements(announcements) {
    return {
      type: SET_ANNOUNCEMENTS,
      payload: announcements,
    }
  },

  updateTeam(team) {
    return {
      type: UPDATE_TEAM,
      payload: team,
    }
  },

  deleteTeam(teamId) {
    return {
      type: DELETE_TEAM,
      payload: teamId,
    }
  },

  clearEvents: () => ({
    type: CLEAR_EVENTS,
  }),

  setTeams: (teams) => ({
    type: SET_TEAMS,
    payload: teams,
  }),

  clearTeams: () => ({
    type: CLEAR_TEAMS,
  }),

  setTeam: (team) => ({
    type: SET_TEAM,
    payload: team,
  }),

  clearTeam: () => ({
    type: CLEAR_TEAM,
  }),

  clearAll: () => ({
    type: CLEAR_ALL,
  }),

  setMembershipRequests: (membershipRequests) => ({
    type: SET_MEMBERSHIP_REQUESTS,
    payload: membershipRequests,
  }),

  clearMembershipRequests: () => ({
    type: CLEAR_MEMBERSHIP_REQUESTS,
  }),
  loadingTeams: () => ({
    type: LOADING_TEAMS,
  }),
  loadedTeams: () => ({
    type: LOADED_TEAMS,
  }),
}
