import axios from "axios"

export const uploadPresignedPost = async (url, fields, file) => {
  const formData = new FormData()
  for (const f in fields) {
    formData.append(f, fields[f])
  }

  formData.append("file", file)

  return await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "",
    },
  })
}

export default {
  uploadPresignedPost,
}
