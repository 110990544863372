import { createSlice } from "@reduxjs/toolkit"

const session = createSlice({
  name: "session",
  initialState: {
    token: undefined,
    account: undefined,
    accountIsLoading: false,
  },
  reducers: {
    setToken(state, { payload }) {
      state.token = payload
      return state
    },
    clearToken(state) {
      state.token = null
      return state
    },
    setAccount(state, { payload }) {
      state.account = payload
      state.accountIsLoading = false
      return state
    },
    clearAccount(state) {
      state.account = null
      return state
    },
    loadingAccount(state) {
      state.accountIsLoading = true
      return state
    },
  },
})

export const {
  setToken,
  clearToken,
  setAccount,
  clearAccount,
  loadingAccount,
} = session.actions

export default session.reducer
