import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit"
import zeusAPI from "api/zeus"

// thunks
export const fetchPlayerData = createAsyncThunk(
  "playground/fetchPlayerData",
  async () => {
    const data = await zeusAPI.playground.getPlayerData()
    return data
  }
)

export const fetchCompanyChallenges = createAsyncThunk(
  "playground/getCompanyChallenges",
  async (companyId) => {
    // convert to obj structure
    const challengesArr = await zeusAPI.playground.getCompanyChallenges(
      companyId
    )
    return {
      [challengesArr[0].owner || "Unknown"]: challengesArr,
    }
  }
)

// slice
const playgroundSlice = createSlice({
  name: "playground",
  initialState: {
    player: {
      playId: "",
      displayName: "",
      points: 0,
      rank: null,
      remainingWoF: null,
      challengeToWoF: null,
    },
    challenges: {},
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchPlayerData.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        player: {
          ...state.player,
          ...payload,
        },
        status: "complete",
      }
    },
    [fetchCompanyChallenges.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        challenges: {
          ...state.challenges,
          ...payload,
        },
        status: "complete",
      }
    },
    [fetchCompanyChallenges.pending]: (state) => {
      state.status = "loading"
    },
    [fetchCompanyChallenges.rejected]: (state, { payload }) => {
      state.status = "failed"
      state.error = payload
    },
    [fetchPlayerData.pending]: (state) => {
      state.status = "loading"
    },
    [fetchPlayerData.rejected]: (state, { payload }) => {
      state.status = "failed"
      state.error = payload
    },
  },
})

const selectPlayground = (state) => state.playground

export const selectPlaygroundProfile = createSelector(
  [selectPlayground],
  (playground) => playground.player
)

export const selectPlaygroundChallenges = createSelector(
  [selectPlayground],
  (playground) => playground.challenges
)

export default playgroundSlice.reducer
