import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
}) => {
  if (
    (prevRouterProps?.location.pathname.includes("/playground/") &&
      location.pathname.includes("/job-fair/")) ||
    (prevRouterProps?.location.pathname.includes("/job-fair/") &&
      location.pathname.includes("/playground/")) ||
    (prevRouterProps?.location.pathname.includes("/job-fair/") &&
      location.pathname.includes("/job-fair/")) ||
    (prevRouterProps?.location.pathname.includes("/playground/") &&
      location.pathname.includes("/playground/"))
  ) {
    return false
  }

  return true
}
