import axios from "axios"
import constant from "../constant"

export default {
  // create session
  login: (email, password) =>
    axios.post(`${constant.API_BASE_URL}/login/`, {
      email,
      password,
    }),

  // logout session
  logout: () => axios.post(`${constant.API_BASE_URL}/logout/`),

  // register
  signup: (name, email, password) =>
    axios.post(`${constant.API_BASE_URL}/register/`, {
      name,
      email,
      password,
    }),

  forgotPassword: (email) =>
    axios.post(`${constant.API_BASE_URL}/forgot-password/`, { email }),
}
