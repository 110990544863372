import account from "./account"
import auth from "./auth"
import registrations from "./registrations"
import externalAuths from "./externalAuth"
import recruitments from "./recruitments"
import jobs from "./jobs"
import playground from "./playground"
import utils from "./utils"

const zeusAPI = {
  account,
  auth,
  registrations,
  externalAuths,
  recruitments,
  jobs,
  playground,
  utils,
}

export default zeusAPI
