import axios from "axios"
import constant from "../constant"

const baseUrl = `${constant.API_BASE_URL}/external-auths/`

function getHost() {
  const { host, protocol } = window.location
  return encodeURI(`${protocol}//${host}`)
}

export default {
  googleLogin: (id_token) =>
    axios.post(`${baseUrl}google/`, { token_id: id_token }),
  SSOLogin() {
    const SSOWindow = window.open(
      baseUrl + "sso-ui/?sender=" + getHost(),
      "SSO UI",
      "left=50, top=50, width=480, height=480"
    )

    // TODO: Promise should be resolved when the window is closed.
    return new Promise(function (resolve, reject) {
      window.addEventListener(
        "message",
        (e) => {
          if (e.origin === constant.API_BASE_URL) {
            if (SSOWindow) SSOWindow.close()
            resolve(e.data.body)
          } else {
            // const error = new Error("SSO Login failed, please try again.")
            // logError(error, e.data)
          }
        },
        { once: true }
      )
    })
  },
}
