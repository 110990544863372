import axios from "axios"
import constants from "../constant"

const baseUrl = `${constants.API_BASE_URL}/recruitments`

export default {
  getApplications() {
    return axios.get(`${baseUrl}/switch-priority/`)
  },

  switchPriority(tag) {
    return axios.patch(`${baseUrl}/switch-priority/`, { tag })
  },
}
