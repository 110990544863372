/* eslint-env browser */

import axios from "axios"

import { uploadPresignedPost } from "./utils"
import constants from "../constant"

const baseUrl = `${constants.API_BASE_URL}/playground-challenge`

const playgroundProfileSnakeToCamel = (snakeObj) => {
  const {
    player_id: playId,
    display_name: displayName,
    points,
    remainingWoF,
    challenge_to_WoF: challengeToWoF,
    rank,
  } = snakeObj

  return {
    playId,
    displayName,
    points,
    rank,
    remainingWoF,
    challengeToWoF,
  }
}

export default {
  async getPlayerData() {
    return playgroundProfileSnakeToCamel(
      (await axios.get(`${baseUrl}/me/`)).data
    )
  },
  async getCompanyChallenges(companyId) {
    return (await axios.get(`${baseUrl}/company/${companyId}/`)).data
  },
  async getChallenge(challengeId) {
    return (await axios.get(`${baseUrl}/challenges/${challengeId}/`)).data
  },
  async doChallenge(challengeId) {
    return (await axios.post(`${baseUrl}/challenges/${challengeId}/`)).data
  },
  async doUploadChallenge(challengeId) {
    // convenience function for uploading upload challenges
    // since you need to pass in filetype
    // only jpg filetype supported (according to Figma)
    // additional method 'upload' added to the obj for convenience to
    // pending change later
    const data = (
      await axios.post(`${baseUrl}/challenges/${challengeId}/`, {
        file_type: "jpg",
      })
    ).data

    data["upload"] = (file) => {
      const renamedFile = new File([file], data.data.fields.key.split("/")[1])
      return uploadPresignedPost(data.data.url, data.data.fields, renamedFile)
    }

    return data
  },
  async getLeaderboard() {
    return (await axios.get(`${baseUrl}/leaderboard/`)).data
  },
  async playWoF() {
    return (await axios.get(`${baseUrl}/wheel/play/`)).data
  },
  async getQuizQuestion(sessionId) {
    return (
      await axios.get(`${baseUrl}/integrations/quiz/${sessionId}/questions/`)
    ).data
  },
  async postAnswer(challengeId, body) {
    return (
      await axios.post(
        `${baseUrl}/integrations/quiz/${challengeId}/answer/`,
        body
      )
    ).data
  },
}
