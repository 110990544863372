import axios from "axios"
import constants from "../constant"

const baseUrl = `${constants.API_BASE_URL}/accounts/`

export default {
  // get account data based on token
  getAccount: () => axios.get(baseUrl),

  // update account data with all data required
  putAccount: (
    name,
    email,
    birthdate,
    gender,
    institution,
    max_degree,
    phone_number,
    line_id
  ) =>
    axios.put(baseUrl, {
      name,
      email,
      birthdate,
      gender,
      institution,
      max_degree,
      phone_number,
      line_id,
    }),

  // partially update the account data
  patchAccount: (data) => axios.patch(baseUrl, data),

  // account change password
  putPassword: (old_password, new_password) =>
    axios.put(`${baseUrl}change-password/`, { old_password, new_password }),

  postResendActivation: () => axios.post(`${baseUrl}resend-activation/`),

  resetPassword: (token, code, password) =>
    axios.post(`${baseUrl}reset-password/?token=${token}`, {
      code,
      password,
    }),
}
