/* eslint-disable no-undef */
export const isDevelopment =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"

const developmentConstants = {
  // API_BASE_URL: "https://staging.api.compfest.id",
  API_BASE_URL: "https://staging.api.compfest.id",
}

const productionConstants = {
  // API_BASE_URL: "https://staging.api.compfest.id"
  API_BASE_URL: "https://api.compfest.id",
}

export default isDevelopment ? developmentConstants : productionConstants
