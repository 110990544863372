import { toastActions } from "./index"
import { push } from "connected-react-router"

export const toastMiddleware = (store) => (next) => async (action) => {
  try {
    return await next(action)
  } catch (e) {
    if (e.response) {
      const { data } = e.response
      // token is not set, redirect to login
      if (data.detail === "Authentication credentials were not provided.") {
        store.dispatch(push("/login"))
        return
      }

      // or token has expired, redirect to logout
      if (data.detail === "Invalid token.") {
        store.dispatch(push("/logout"))
        return
      }

      if (data.detail) {
        toastActions.showErrorToast(data.detail)
        throw e
      }

      if (data.non_field_errors) {
        toastActions.showErrorToast(data.non_field_errors[0])
        throw e
      }
    }

    if (window.navigator && !window.navigator.onLine) {
      toastActions.showErrorToast(
        "The internet connection appears to be offline, sorry ☹"
      )
    }

    throw e
  }
}
