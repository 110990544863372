import { createSlice } from "@reduxjs/toolkit"

const jobfair = createSlice({
  name: "jobfair",
  initialState: {
    showContent: null,
    searchHistory: [],
    keyword: "",
    location: "",
    employmentType: [],
    company: [],
    industry: [],
    showMobileSearch: false,
    showMobileFilter: false,
    showDropDownContent: null,
    customKeyword: null,
  },
  reducers: {
    setShowContent(state, { payload }) {
      state.showContent = payload === state.showContent ? null : payload
      return state
    },
    setSearchHistory(state, { payload }) {
      state.searchHistory = payload
      return state
    },
    clearSearchHistory(state) {
      state.searchHistory = []
      return state
    },
    setKeyword(state, { payload }) {
      state.keyword = payload
      return state
    },
    setLocation(state, { payload }) {
      state.location = payload
      return state
    },
    setEmploymentType(state, { payload }) {
      state.employmentType = payload
      return state
    },
    setCompany(state, { payload }) {
      state.company = payload
      return state
    },
    setIndustry(state, { payload }) {
      state.industry = payload
      return state
    },
    clearFilter(state, { payload }) {
      state = {
        searchHistory: state.searchHistory,
        keyword: "",
        location: "",
        employmentType: [],
        company: [],
        industry: [],
      }
      return state
    },
    setShowMobileSearch(state, { payload }) {
      state.showMobileSearch = payload
      return state
    },
    setShowMobileFilter(state, { payload }) {
      state.showMobileFilter = payload
      return state
    },
    setShowDropDownContent(state, { payload }) {
      state.showDropDownContent = payload
      return state
    },
    setCustomKeyword(state, { payload }) {
      state.customKeyword = payload
      return state
    },
  },
})

export const {
  setShowContent,
  setSearchHistory,
  clearSearchHistory,
  setKeyword,
  setLocation,
  setEmploymentType,
  setCompany,
  setIndustry,
  clearFilter,
  setShowMobileSearch,
  setShowMobileFilter,
  setShowDropDownContent,
  setCustomKeyword,
} = jobfair.actions

export default jobfair.reducer
