import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist"

import zeusAPI from "../api/zeus"
import reducer from "./reducers"
import logger from "./middlewares/logger"
import { toastMiddleware, toastActions } from "./middlewares/toast"

const middleware = [
  ...getDefaultMiddleware({
    thunk: { extraArgument: { toastActions, zeusAPI } },
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  toastMiddleware,
]
if (process.env.NODE_ENV === "development") middleware.push(logger)

export default function useStore() {
  const store = configureStore({
    reducer,
    middleware,
    devTools: process.env.NODE_ENV === "development",
  })

  const persistor = persistStore(store)
  return { store, persistor }
}
