/* eslint-env browser */

import axios from "axios"

import constants from "../constant"
import { uploadPresignedPost } from "./utils"
import { toastActions } from "../../redux/toast"

const baseUrl = `${constants.API_BASE_URL}/jobfair`

const jobSnakeToCamel = (snakeObj) => {
  const {
    id,
    cv_url: cvURL,
    portfolio_url: portfolioURL,
    cover_letter: coverLetter,
    job_opening: { id: openingId, opening_name: position },
    salary_lower: salaryLower,
    salary_upper: salaryUpper,
    is_applied: applied,
    is_saved: saved,
    applied_date: appliedDate,
  } = snakeObj

  return {
    id,
    openingId,
    position,
    salaryLower,
    salaryUpper,
    applied,
    saved,
    appliedDate,
    cvURL,
    portfolioURL,
    coverLetter,
  }
}

export default {
  async getProfile() {
    return axios.get(`${baseUrl}/profile/`)
  },
  async getJobs() {
    const profile = await axios.get(`${baseUrl}/profile/`)
    const jobs = profile.data.applied_job.map(jobSnakeToCamel)
    const jobsObj = {}
    jobs.forEach((x) => {
      jobsObj[x.id] = x
    })
    return jobsObj
  },
  async getJob(applicationId) {
    return jobSnakeToCamel(
      (await axios.get(`${baseUrl}/application/${applicationId}/`)).data
    )
  },
  async addSaved(openingId) {
    const response = await axios.post(`${baseUrl}/opening/${openingId}/save`)
    return jobSnakeToCamel(response.data)
  },
  async removeSaved(applicationId) {
    const response = await axios.patch(
      `${baseUrl}/application/${applicationId}/`,
      {
        is_saved: false,
      }
    )
    return jobSnakeToCamel(response.data)
  },
  async uploadFile({ openingId, file }) {
    const presignedData = (
      await axios.get(`${baseUrl}/opening/${openingId}/upload`)
    ).data

    const renamedFile = new File([file], presignedData.fileName)
    await uploadPresignedPost(
      presignedData.url,
      presignedData.fields,
      renamedFile
    )

    return presignedData.url + presignedData.fields.key
  },
  async applyToJob({ openingId, applicationData, file }) {
    let cvURL
    if (file) {
      if (file.name.slice(0, 5) === "https") {
        cvURL = file.name
      } else {
        cvURL = await this.uploadFile({ openingId: openingId, file: file })
      }
    } else {
      cvURL = applicationData.cvURL
    }

    const response = await axios
      .post(`${baseUrl}/opening/${openingId}/`, {
        cv_url: cvURL,
        portfolio_url: applicationData.portfolioURL,
        cover_letter: applicationData.coverLetter,
        salary_lower: Number(applicationData.salaryLower),
        salary_upper: Number(applicationData.salaryUpper),
      })
      .catch((error) => {
        toastActions.showToast(error.response.data.detail)
      })

    return jobSnakeToCamel(response.data)
  },
  async editApplication(applicationId, applicationData, file) {
    let cvURL
    if (file) {
      cvURL = await this.uploadFile(applicationData.openingId, file)
    } else {
      cvURL = applicationData.cvURL
    }

    const response = await axios.post(
      `${baseUrl}/application/${applicationId}/`,
      {
        cv_url: cvURL,
        portfolio_url: applicationData.portfolioURL,
        cover_letter: applicationData.coverLetter,
        salary_lower: Number(applicationData.salaryLower),
        salary_upper: Number(applicationData.salaryUpper),
      }
    )

    return jobSnakeToCamel(response.data)
  },
  async giveFeedback(data) {
    return await axios.patch(`${baseUrl}/profile/`, data)
  },
}
