import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit"
import zeusAPI from "api/zeus/"

// thunks
export const fetchApplicant = createAsyncThunk(
  "jobs/fetchApplicant",
  async () => {
    return await zeusAPI.jobs.getProfile()
  }
)

export const giveFeedback = createAsyncThunk("jobs/feedback", async (data) => {
  return await zeusAPI.jobs.giveFeedback(data)
})

export const fetchJobs = createAsyncThunk("jobs/fetchApplied", async () => {
  return await zeusAPI.jobs.getJobs()
})

export const fetchJob = createAsyncThunk(
  "jobs/fetchApplied",
  async (applicationId) => {
    return await zeusAPI.jobs.getJob(applicationId)
  }
)

export const addSavedJob = createAsyncThunk(
  "jobs/addSaved",
  async (openingId) => {
    return await zeusAPI.jobs.addSaved(openingId)
  }
)

export const removeSavedJob = createAsyncThunk(
  "jobs/removeSaved",
  async (id) => {
    return await zeusAPI.jobs.removeSaved(id)
  }
)

export const applyToJob = createAsyncThunk(
  "jobs/apply",
  async ({ openingId, applicationData, file }) => {
    return await zeusAPI.jobs.applyToJob({ openingId, applicationData, file })
  }
)

export const editApplication = createAsyncThunk(
  "jobs/editApplication",
  async ({ applicationId, applicationData, file }) => {
    return await zeusAPI.jobs.editApplication(
      applicationId,
      applicationData,
      file
    )
  }
)

const handleLoadingReducer = (state) => {
  state.status = "loading"
}

const handleErrorReducer = (state, { payload }) => {
  state.status = "failed"
  state.error = payload
}

// slice
const jobsSlice = createSlice({
  name: "jobs",
  initialState: {
    jobs: {},
    applicant: {},
    status: "idle",
    error: null,
  },
  reducers: {
    setJobs(state, { payload }) {
      state.jobs = payload
    },
    setApplicant(state, { payload }) {
      state.applicant = payload.data
    },
  },
  extraReducers: {
    [fetchJobs.fulfilled]: (state, { payload }) => {
      state.status = "complete"
      state.jobs = payload
    },
    [fetchApplicant.fulfilled]: (state, { payload }) => {
      state.applicant = payload.data
    },
    [giveFeedback.fulfilled]: (state, { payload }) => {
      state.status = "complete"
      state.applicant = payload.data
    },
    [addSavedJob.fulfilled]: (state, { payload }) => {
      state.jobs[payload.id] = payload
    },
    [removeSavedJob.fulfilled]: (state, { payload }) => {
      state.jobs[payload.id] = payload
    },
    [applyToJob.fulfilled]: (state, { payload }) => {
      state.status = "complete"
      state.jobs[payload.id] = payload
    },
    [editApplication.fulfilled]: (state, { payload }) => {
      state.status = "complete"
      state.jobs[payload.id] = payload
    },
    // [addSavedJob.pending]: handleLoadingReducer,
    [addSavedJob.rejected]: handleErrorReducer,
    // [removeSavedJob.pending]: handleLoadingReducer,
    [fetchApplicant.pending]: handleLoadingReducer,
    [fetchApplicant.rejected]: handleErrorReducer,
    [removeSavedJob.rejected]: handleErrorReducer,
    [fetchJobs.pending]: handleLoadingReducer,
    [fetchJobs.rejected]: handleErrorReducer,
    [fetchJob.pending]: handleLoadingReducer,
    [fetchJob.rejected]: handleErrorReducer,
    [applyToJob.pending]: handleLoadingReducer,
    [applyToJob.rejected]: handleErrorReducer,
    [editApplication.pending]: handleLoadingReducer,
    [editApplication.rejected]: handleErrorReducer,
    [giveFeedback.pending]: handleLoadingReducer,
    [giveFeedback.rejected]: handleErrorReducer,
  },
})

const selectJobs = (state) => state.jobs

export const selectSavedJobs = createSelector([selectJobs], (jobs) => {
  try {
    return Object.entries(jobs.jobs)
      .map(([k, v]) => v)
      .filter((v) => v.saved)
  } catch (err) {
    return []
  }
})

export const selectAppliedJobs = createSelector([selectJobs], (jobs) => {
  try {
    return Object.entries(jobs.jobs)
      .map(([k, v]) => v)
      .filter((v) => v.applied)
  } catch (err) {
    return []
  }
})

export const selectAllJobs = createSelector([selectJobs], (jobs) => {
  try {
    return jobs.jobs
  } catch (err) {
    return []
  }
})

export const selectJob = createSelector([selectJobs], (jobs) => jobs)

export default jobsSlice.reducer
