// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academy-js": () => import("./../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-applications-applied-job-js": () => import("./../src/pages/applications/applied-job.js" /* webpackChunkName: "component---src-pages-applications-applied-job-js" */),
  "component---src-pages-applications-index-js": () => import("./../src/pages/applications/index.js" /* webpackChunkName: "component---src-pages-applications-index-js" */),
  "component---src-pages-applications-saved-js": () => import("./../src/pages/applications/saved.js" /* webpackChunkName: "component---src-pages-applications-saved-js" */),
  "component---src-pages-competition-js": () => import("./../src/pages/competition.js" /* webpackChunkName: "component---src-pages-competition-js" */),
  "component---src-pages-compfesttalks-channel-js": () => import("./../src/pages/compfesttalks/channel.js" /* webpackChunkName: "component---src-pages-compfesttalks-channel-js" */),
  "component---src-pages-compfesttalks-js": () => import("./../src/pages/compfesttalks.js" /* webpackChunkName: "component---src-pages-compfesttalks-js" */),
  "component---src-pages-compfesttalks-player-js": () => import("./../src/pages/compfesttalks/player.js" /* webpackChunkName: "component---src-pages-compfesttalks-player-js" */),
  "component---src-pages-dashboard-calendar-js": () => import("./../src/pages/dashboard/calendar.js" /* webpackChunkName: "component---src-pages-dashboard-calendar-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-profile-js": () => import("./../src/pages/dashboard/profile.js" /* webpackChunkName: "component---src-pages-dashboard-profile-js" */),
  "component---src-pages-dashboard-task-js": () => import("./../src/pages/dashboard/task.js" /* webpackChunkName: "component---src-pages-dashboard-task-js" */),
  "component---src-pages-dashboard-team-js": () => import("./../src/pages/dashboard/team.js" /* webpackChunkName: "component---src-pages-dashboard-team-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-fair-index-js": () => import("./../src/pages/job-fair/index.js" /* webpackChunkName: "component---src-pages-job-fair-index-js" */),
  "component---src-pages-job-fair-search-js": () => import("./../src/pages/job-fair/search.js" /* webpackChunkName: "component---src-pages-job-fair-search-js" */),
  "component---src-pages-playground-about-js": () => import("./../src/pages/playground/about.js" /* webpackChunkName: "component---src-pages-playground-about-js" */),
  "component---src-pages-playground-index-js": () => import("./../src/pages/playground/index.js" /* webpackChunkName: "component---src-pages-playground-index-js" */),
  "component---src-pages-playground-leaderboard-js": () => import("./../src/pages/playground/leaderboard.js" /* webpackChunkName: "component---src-pages-playground-leaderboard-js" */),
  "component---src-pages-playground-prizes-js": () => import("./../src/pages/playground/prizes.js" /* webpackChunkName: "component---src-pages-playground-prizes-js" */),
  "component---src-pages-playground-quiz-js": () => import("./../src/pages/playground/quiz.js" /* webpackChunkName: "component---src-pages-playground-quiz-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-welcome-js": () => import("./../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-templates-academy-competition-desc-js": () => import("./../src/templates/academy-competition-desc.js" /* webpackChunkName: "component---src-templates-academy-competition-desc-js" */),
  "component---src-templates-detail-lowongan-js": () => import("./../src/templates/detail-lowongan.js" /* webpackChunkName: "component---src-templates-detail-lowongan-js" */),
  "component---src-templates-perusahaan-challenge-js": () => import("./../src/templates/perusahaan-challenge.js" /* webpackChunkName: "component---src-templates-perusahaan-challenge-js" */),
  "component---src-templates-perusahaan-job-fair-js": () => import("./../src/templates/perusahaan-job-fair.js" /* webpackChunkName: "component---src-templates-perusahaan-job-fair-js" */)
}

